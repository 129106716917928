
// Libraries
import * as React from 'react'
import moment from 'moment'
import debounce from 'debounce-promise'

// Services
import ApiService from '../../../services/api'
import EmploymentStatusService from '../../../services/employmentStatus'
import OrganisationService from '../../../services/organisation'
import TrustService from '../../../services/trust'
import TagService from '../../../services/tag'

// Components
import Modal from '../../modal'
import AdminUserViewRow from './adminUserViewRow'
import InputFactory from '../../inputFactory'
import Button from '../../button'

// Contexts
import NotificationContext from '../../../contexts/notification'

class AdminUserEdit extends React.Component {
	state = {
		inputValues: {
			user: {},
			role: {},
			address: {
				is_primary: 1,
				is_billing: 1,
				is_shipping: 1,
				country_code: 'GB'
			}
		},
		inputErrors: {
			user: {},
			role: {},
			address: {}
		},
		employmentStatuses: [],
		organisations: [],
		trusts: [],
		isSubmitting: false
	}

	static contextType = NotificationContext

	constructor(props) {
		super(props);

		const wait = 1000; // milliseconds
		const loadTagOptions = (inputValue, type) => this.loadTagOptions(inputValue, type);
		this.debouncedLoadTagOptions = debounce(loadTagOptions, wait, {
			leading: true
		});
	}

	render() {
		const {user, handleClose} = this.props
		const {inputValues, inputErrors, employmentStatuses, organisations, trusts, isSubmitting} = this.state
		const ballotCounts = {
			entries_total: user.ballots?.length,
			entries_success: 0
		}
		const addressErrors = inputErrors ? {
			street: inputErrors.address.street,
			street_2: inputErrors.address.street_2,
			state: inputErrors.address.state,
			postal_code: inputErrors.address.postal_code,
			city: inputErrors.address.city
		} : {}

		user.ballots?.forEach((ballot) => {
			if (ballot.status_id === 2) {
				ballotCounts.entries_success ++
			}
		})

		return <Modal handleClose={handleClose} modalRight adminForm>
			<div className="admin-form admin-form--user">
				<div className="admin-form__header">
					<div className="column">
						<h2>User Profile</h2>
						<h3>{user.first_name} {user.last_name}</h3>
						<p>Registered: {moment(user.created_at).format('DD MMM YYYY')}</p>
					</div>
					<div className="column">
					</div>
				</div>
				<div className="admin-form__info">
					<p><b>Entries</b> {ballotCounts.entries_total}</p>
					<p><b>Successful entries</b> {ballotCounts.entries_success}</p>
				</div>
				<div className="admin-form__form">
					<form onSubmit={this.handleSubmit} encType="multipart/form-data">
						<AdminUserViewRow
							label="First Name"
							value={<InputFactory
								type="text"
								name="first_name"
								scope="user"
								value={inputValues?.user?.first_name}
								error={inputErrors?.user?.first_name}
								onChange={this.handleInputChange}
							/>} />
						<AdminUserViewRow
							label="Surname"
							value={<InputFactory
								type="text"
								name="last_name"
								scope="user"
								value={inputValues?.user?.last_name}
								error={inputErrors?.user?.last_name}
								onChange={this.handleInputChange}
							/>} />
						<AdminUserViewRow
							label="Email"
							value={<InputFactory
								type="text"
								name="email"
								scope="user"
								value={inputValues?.user?.email}
								error={inputErrors?.user?.email}
								onChange={this.handleInputChange}
							/>} />
						{(inputValues?.user?.email && inputValues?.user?.email !== user.email) && <AdminUserViewRow
							checkbox
							label="Verify New Email"
							value={<InputFactory
								type="checkbox"
								name="mark_email_as_verified"
								scope="user"
								defaultChecked={!!inputValues?.user?.mark_email_as_verified}
								error={inputErrors?.user?.mark_email_as_verified}
								onChange={this.handleInputChange}
								checkboxEndeavour
							/>} />}
						<AdminUserViewRow
							label="User Type"
							value={<InputFactory
								type="select"
								empty={false}
								name="role"
								scope="role"
								value={inputValues?.role?.role}
								error={inputErrors?.role?.role}
								onChange={this.handleInputChange}
								options={[
									{
										value: 'Carer',
										label: 'Carer'
									},
									{
										value: 'CfC Admin',
										label: 'CfC Admin'
									}
								]}
							/>} />
						<AdminUserViewRow
							label="Address"
							value={<InputFactory
								type="address"
								name="address"
								value={inputValues?.address}
								error={addressErrors}
								onChange={this.handleInputChange}/>}
						/>
						<AdminUserViewRow
							label="Job Title"
							value={<InputFactory
								type="text"
								name="job_title"
								scope="user"
								value={inputValues?.user?.job_title}
								error={inputErrors?.user?.job_title}
								onChange={this.handleInputChange}
							/>} />
						<AdminUserViewRow
							label="Employer"
							value={<InputFactory
								type="select"
								empty={false}
								name="organisation_id"
								scope="user"
								value={inputValues?.user?.organisation_id}
								error={inputErrors?.user?.organisation_id}
								onChange={this.handleInputChange}
								options={organisations}
							/>} />
						{(inputValues?.user?.organisation_id?.value && inputValues?.user?.organisation_id.value === 1) && <AdminUserViewRow
							label="Trust"
							value={<InputFactory
								type="select"
								empty={false}
								name="trust_id"
								scope="user"
								value={inputValues?.user?.trust_id}
								error={inputErrors?.user?.trust_id}
								onChange={this.handleInputChange}
								options={trusts}
							/>} />}
						<AdminUserViewRow
							label="Employment Status"
							value={<InputFactory
								type="select"
								empty={false}
								name="employment_status_id"
								scope="user"
								value={inputValues?.user?.employment_status_id}
								error={inputErrors?.user?.employment_status_id}
								onChange={this.handleInputChange}
								options={employmentStatuses}
							/>} />
						<AdminUserViewRow
							label="Mobile Number"
							value={<InputFactory
								type="text"
								name="phone"
								scope="user"
								placeholder="+44"
								value={inputValues?.user?.phone}
								error={inputErrors?.user?.phone}
								onChange={this.handleInputChange}
							/>} />
						<AdminUserViewRow
							label="Work ID"
							value={<InputFactory
								type="file"
								name="identification"
								scope="user"
								value={inputValues?.user?.identification}
								initialFilePreview={inputValues?.user?.identificationPreview || null}
								error={inputErrors?.user?.identification}
								onChange={this.handleInputChange}
							/>} />
						<AdminUserViewRow
							checkbox
							label="Flagged for Non-Attendance?"
							value={<InputFactory
								type="checkbox"
								name="non_attendance_flag"
								scope="user"
								defaultChecked={!!inputValues?.user?.non_attendance_flag}
								error={inputErrors?.user?.non_attendance_flag}
								onChange={this.handleInputChange}
								checkboxEndeavour
							/>} />
						<AdminUserViewRow
							label="Tags"
							value={<InputFactory
								type="select-tags"
								name="tags"
								scope="user"
								value={inputValues?.user?.tags}
								error={inputErrors?.user?.tags}
								onChange={this.handleInputChange}
								isMulti
								noOptionsMessage={() => "Start typing to find or create a tag ..."}
								loadOptions={(inputValue) => this.debouncedLoadTagOptions(inputValue)}
							/>} />

						<div className="admin-form__submit-wrap">
							<Button type="button" onClick={handleClose} colorEndeavour hollow>Discard Changes</Button>
							<Button type="submit" isLoading={isSubmitting} colorEndeavour>Save Changes</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	}

	async componentDidMount() {
		this.fetchEmploymentStatuses()
		this.fetchOrganisations()
		this.fetchTrusts()

		const {user} = this.props

		const {inputValues} = this.state
		inputValues.user.registration_complete = user.registration_complete
		inputValues.user.first_name = user.first_name
		inputValues.user.last_name = user.last_name
		inputValues.user.email = user.email
		inputValues.user.job_title = user.job_title
		inputValues.user.non_attendance_flag = user.non_attendance_flag
		inputValues.user.mark_email_as_verified = false

		if (user.organisation) {
			inputValues.user.organisation_id = {
				label: user.organisation.name,
				value: user.organisation.id
			}
		}
		if (user.trust) {
			inputValues.user.trust_id = {
				label: user.trust.name,
				value: user.trust.id
			}
		}
		if (user.employment_status) {
			inputValues.user.employment_status_id = {
				label: user.employment_status.name,
				value: user.employment_status.id
			}
		}
		inputValues.user.phone = user.phone

		if (user.roles.length) {
			inputValues.role.role = {label: user.roles[0].name, value: user.roles[0].name}
		}

		if (user.addresses.length) {
			inputValues.address = user.addresses[0]
		}

		inputValues.user.tags = []
		user.tags.forEach((tag) => {
			inputValues.user.tags.push({label: tag.name.en, value: tag.name.en})
		})

		inputValues.user.identificationPreview = user.identification
	}

	async fetchEmploymentStatuses() {
		const employmentStatusService = new EmploymentStatusService()

		const employmentStatusServiceResponse = await employmentStatusService.get()
		let employmentStatuses = []
		employmentStatusServiceResponse.data.forEach((employmentStatus) =>  {
			employmentStatuses.push({
				label: employmentStatus?.name,
				value: employmentStatus?.id,
			})
		})
		this.setState({employmentStatuses})
	}

	async fetchOrganisations() {
		const organisationService = new OrganisationService()

		const organisationResponse = await organisationService.get()
		let organisations = []
		organisationResponse.data.map(function(organisation)  {
			organisations.push({
				label: organisation?.name,
				value: organisation?.id,
			})
			return organisations
		})
		this.setState({organisations})
	}

	async fetchTrusts() {
		const trustService = new TrustService()

		const trustResponse = await trustService.get()
		let trusts = []
		trustResponse.data.map(function(trust)  {
			trusts.push({
				label: trust.name,
				value: trust.id,
			})
			return trusts
		})
		this.setState({trusts})
	}

	loadTagOptions = (inputValue) => {
		if (!inputValue) {
			return []
		}

		const tagService = new TagService()
		return tagService.getOptions(inputValue)
			.then((response) => {
				if (response.success) {
					return response.data
				}
				else {
					const {addNotification} = this.context
					addNotification('Tag search failed', 'error')
					return []
				}
			}).catch(() => {
				const {addNotification} = this.context
				addNotification('Tag search failed', 'error')
			})
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) {
			// Update field value
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async (event) => {
		event.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const {user, handleEditComplete} = this.props
		const {inputValues, inputErrors} = this.state

		// prepare user data
		const userData = new FormData()
		let scope = 'user'
		if (inputValues[scope]['tags'] && inputValues[scope]['tags'].length) {
			const tagString = inputValues[scope]['tags'].map((tag) => {
				return tag.label
			}).join(',')
			userData.set('tags', tagString)
		}
		Object.keys(inputValues[scope]).forEach(key => {
			if (['organisation_id', 'trust_id', 'employment_status_id'].includes(key)) {
				userData.set(key, inputValues[scope][key]['value'])
			}
			else if (['non_attendance_flag', 'mark_email_as_verified'].includes(key)) {
				userData.set(key, inputValues[scope][key] === true ? '1' : '0')
			}
			else if (key !== 'tags') {
				userData.set(key, inputValues[scope][key])
			}
		})

		const apiService = new ApiService()

		// submit user data
		await apiService.put(`users/${user.id}`, {body: userData}, true)
			.then(async (userResponse) => {
				if (userResponse.success) {
					// role change?
					if (userResponse.data.registration_complete === 1 && userResponse.data.roles.length && userResponse.data.roles[0]?.name !== inputValues['role']['role']['value']) {
						// add new role
						apiService.put(`users/${user.id}/roles`, {
							body: JSON.stringify({
								role: inputValues['role']['role']['value']
							})
						})

						// remove old role
						apiService.delete(`users/${user.id}/roles`, {
							body: JSON.stringify({
								role: userResponse.data.roles[0]?.name
							})
						})
					}

					// prepare address data
					inputValues['address']['given_name'] = userResponse.data['first_name']
					inputValues['address']['family_name'] = userResponse.data['last_name']
					const addressData = JSON.stringify(inputValues['address'])

					const {addNotification} = this.context

					if (userResponse.data.registration_complete === 0) {
						addNotification('User profile updated', 'success')
						handleEditComplete(userResponse.data)
					} else {
						// submit address data
						await apiService.put(`users/${user.id}/address/primary`, {body: addressData})
							.then((addressResponse) => {
								if (addressResponse.success) {
									addNotification('User profile updated', 'success')
									handleEditComplete(addressResponse.data)
								}
								else {
									inputErrors['address'] = addressResponse.errors
									this.setState({
										inputErrors: inputErrors,
										isSubmitting: false
									})
								}
							})
							.catch(err => console.error(err))
					}
				}
				else {
					inputErrors['user'] = userResponse.errors
					this.setState({
						inputErrors: inputErrors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default AdminUserEdit
