
// Libraries
import * as React from 'react'
import moment from 'moment'
import Classnames from 'classnames'

// Components
import Modal from '../../modal'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faBan, faTrashAlt, faTicketAlt, faMailBulk, faUnlockAlt,
	faPaperPlane, faKey, faIdBadge, faEllipsisH, faTimes} from '@fortawesome/pro-solid-svg-icons'
import {faTimesCircle} from '@fortawesome/pro-regular-svg-icons'
import {faStripeS} from '@fortawesome/free-brands-svg-icons'
import AdminUserViewRow from './adminUserViewRow'
import Button from '../../button'
import ReactTooltip from 'react-tooltip'

class AdminUserView extends React.Component {
	state = {
		showBadge: false,
		openActionMenu: false,
	}

	render() {
		const {user, handleClose, handleOpenEdit, handleConfirmBan, handleConfirmUnban,
			handleConfirmDelete, handleConfirmSendPasswordReset, handleConfirmSendVerifyEmail} = this.props
		const {showBadge} = this.state

		const ballotCounts = {
			entries_total: user.ballots?.length,
			entries_success: 0
		}
		user.ballots?.forEach((ballot) => {
			if (ballot.status_id === 2) {
				ballotCounts.entries_success ++
			}
		})

		const stripeLink = user.stripe_id
			? `https://dashboard.stripe.com/customers/${user.stripe_id}`
			: `https://dashboard.stripe.com/search?query=${user.email}`

		const mailgunLink = `https://app.eu.mailgun.com/app/sending/domains/outbound.concertsforcarers.org.uk/logs?search=${user.email}`

		const dcAnswers = user.user_answers.reduce(function (r, a) {
			r[a.answer.question_id] = r[a.answer.question_id] || []
			r[a.answer.question_id].push(a)
			return r
		}, [])

		const currencyFormatter = new Intl.NumberFormat("en-GB", {
			style: "currency",
			currency: "GBP",
			minimumFractionDigits: 2,
			currencyDisplay: "symbol",
		})

		const actionsMenuClasses = Classnames([
			'admin-view__actions-menu',
			{
				'admin-view__actions-menu--open': this.state.openActionMenu,
			},
		])

		return <Modal handleClose={handleClose} modalRight>
			<div className="admin-view admin-view--user">
				<div className="admin-view__header">
					<div className="column">
						<h2>User Profile</h2>
						<h3>{user.first_name} {user.last_name}</h3>
						<p>Registered: {moment(user.created_at).format('DD MMM YYYY')}</p>
					</div>
					<div className="column">
						<div className={actionsMenuClasses}>
							<Button onClick={() => this.setState({openActionMenu: !this.state.openActionMenu})} iconOnly data-tip  data-for="tooltip-open-actions-menu">
								<FontAwesomeIcon icon={faEllipsisH} title="Ellipsis vertical icon" />
								<FontAwesomeIcon icon={faTimes} title="Times icon" />
								<span className="sr-only">Toggle User Actions</span>
							</Button>
							<ReactTooltip id="tooltip-open-actions-menu" place="top" backgroundColor="#005EB8" textColor="#fff">
								<span>Toggle User Actions</span></ReactTooltip>

							<ul>
								<li><Button onClick={() => handleOpenEdit(user.id)} iconOnly>
									<FontAwesomeIcon icon={faEdit} title="Edit icon" />
									<span className="text">Edit User</span>
								</Button></li>
								<li><Button to={`/admin/users/ballots/${user.id}`} iconOnly>
									<FontAwesomeIcon icon={faTicketAlt} title="Ticket icon" />
									<span className="text">See Ballots</span>
								</Button></li>
								{(!user.banned_at && user.roles[0]?.id === 1) && <li><Button onClick={() => handleConfirmBan(user.id)} iconOnly danger>
									<FontAwesomeIcon icon={faBan} title="Delete icon" />
									<span className="text">Ban User</span>
								</Button></li>}
								{user.banned_at && <li><Button onClick={() => handleConfirmUnban(user.id)} iconOnly danger>
									<FontAwesomeIcon icon={faUnlockAlt} title="Unlock icon" />
									<span className="text">Reactivate User</span>
								</Button></li>}
								<li><Button onClick={() => handleConfirmDelete(user.id)} iconOnly danger>
									<FontAwesomeIcon icon={faTrashAlt} title="Delete icon" />
									<span className="text">Delete User</span>
								</Button></li>
								<li><Button to={stripeLink} target="_blank" iconOnly>
									<FontAwesomeIcon icon={faStripeS} title="Stripe icon" />
									<span className="text">Stripe Customer</span>
								</Button></li>
								<li><Button to={mailgunLink} target="_blank" iconOnly>
									<FontAwesomeIcon icon={faMailBulk} title="Bulk email icon" />
									<span className="text">Mailgun Emails</span>
								</Button></li>
								<li><Button to={`/admin/broadcasts/new/?user_id=${user.id}&email=${user.email}`} target="_blank" iconOnly>
									<FontAwesomeIcon icon={faPaperPlane} title="Paper plane icon" />
									<span className="text">Send Broadcast</span>
								</Button></li>
								{user.email_verified_at && <li><Button onClick={handleConfirmSendPasswordReset} iconOnly>
									<FontAwesomeIcon icon={faKey} title="Key icon" />
									<span className="text">Password Reset</span>
								</Button></li>}
								{!user.email_verified_at && <li><Button onClick={handleConfirmSendVerifyEmail} iconOnly>
									<FontAwesomeIcon icon={faIdBadge} title="ID badge icon" />
									<span className="text">Verify Email</span>
								</Button></li>}
							</ul>
						</div>
					</div>
				</div>

				{user.banned_at && <div className="admin-view__banned">
					<p><b>This user was banned by</b> {user.banned_by ? user.banned_by.first_name : 'unknown'} <b>on</b> {moment(user.banned_at).format('DD MMM YYYY')}</p>
					<p><b>Reason for banning:</b> {user.banned_reason}</p>
				</div>}

				<div className="admin-view__info">
					<p><b>Entries</b> {ballotCounts.entries_total}</p>
					<p><b>Successful entries</b> {ballotCounts.entries_success}</p>
				</div>

				<div className="admin-view__view">
					<AdminUserViewRow label="First Name" value={user.first_name} />
					<AdminUserViewRow label="Last Name" value={user.last_name} />
					<AdminUserViewRow label="Email" value={user.email} />
					<AdminUserViewRow label="User Type" value={user.roles.length ? user.roles[0].name : '-'} />
					<AdminUserViewRow label="Postcode" value={<>
						{user.addresses.length ? user.addresses[0].postal_code : '-'}
						<span className="postcode-changes">Changed {user.postal_code_change_count} times</span></>} />
					<AdminUserViewRow label="Job Title" value={user.job_title ? user.job_title : '-'} />
					<AdminUserViewRow label="Employer" value={user.organisation ? user.organisation.name : '-'} />
					{(user.organisation?.id === 1) &&
						<AdminUserViewRow label="Trust" value={user.trust ? user.trust.name : '-'} />}
					<AdminUserViewRow label="Employment Status" value={user.employment_status?.name ? user.employment_status.name : '-'} />
					<AdminUserViewRow label="Mobile Number" value={<><span className="mobile-code">+44</span> {user.phone ? user.phone : '-'}</>} />
					{user.identification && <>
						<AdminUserViewRow label="Badge/Trust" value={<button onClick={() => this.setState({showBadge: true})}>See badge</button>} />
						{showBadge && <div className="badge-wrap">
							<button onClick={() => this.setState({showBadge: false})}>
								<FontAwesomeIcon icon={faTimesCircle} title="Close icon" />
								<span className="sr-only"> Hide badge</span>
							</button>
							<img src={user.identification} alt="Work ID" />
						</div>}
					</>}
					<AdminUserViewRow label="Flagged for Non-Attendance?" value={user.non_attendance_flag ? 'Yes' : 'No'} />
					<AdminUserViewRow label="Data Collection" value={dcAnswers.length
						? <ul className="dc-answers">{dcAnswers.map((question) => {
							return <li>{question[0].answer.question.text}
								<ul>{question.map((answer) => {
									return <li>{answer.answer.text}</li>
								})}</ul>
							</li>
						})}</ul>
						: 'None'} />
					<AdminUserViewRow label="Tags" value={user.tags.length
						? <ul className="tags">{user.tags.map((tag) => {
							return <li>{tag.name.en}</li>
						})}</ul>
						: 'None'} />
					<AdminUserViewRow label="Stripe Lifetime Value" value={user.stripe_lifetime
						? <ul className="stripe-lifetime-value">
							<li>Received: {currencyFormatter.format(user.stripe_lifetime.amount_received / 100)}</li>
							<li>Refunded: {currencyFormatter.format(user.stripe_lifetime.amount_refunded / 100)}</li>
							<li>Value: {currencyFormatter.format(user.stripe_lifetime.lifetime_value / 100)}</li>
						</ul>
						: 'N/A'} />
				</div>
			</div>
		</Modal>
	}
}

export default AdminUserView
