
// Libraries
import * as React from 'react'

// Components
import Modal from "../../modal"
import Button from "../../button";

class AdminUserConfirmDelete extends React.Component {
	state = {
		isConfirming: false
	}

	render() {
		const {user, handleClose} = this.props
		const {isConfirming} = this.state

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-confirm">
				<h2>Delete {user.first_name} {user.last_name} as a user?</h2>
				<p>Are you sure you want to delete this user?  All data associated to this profile will be lost</p>
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>Back</Button></li>
					<li><Button onClick={this.handleConfirm} isLoading={isConfirming} colorPomegranate>Delete User</Button></li>
				</ul>
			</div>
		</Modal>
	}

	handleConfirm = () => {
		const {user, handleConfirm} = this.props
		this.setState({
			isConfirming: true
		})
		handleConfirm(user.id)
	}
}

export default AdminUserConfirmDelete
