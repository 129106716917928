
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class OrganisationService {
	async get(id = null) {
		if(!id) {
			return await apiService.get('organisations')
		}

		return await apiService.get(`organisations/${id}`)
	}
}
