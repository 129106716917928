
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class EmploymentStatusService {
	async get() {
		return await apiService.get('registration/employmentStatuses')
	}
}
