
// Libraries
import * as React from 'react'

// Components
import AdminUsersTableRow from "./adminUsersTableRow"
import TableSort from '../tableSort'

class AdminUsersTable extends React.Component {
	state = {
		usersMessage: "Retrieving user data ...",
		users: [],
		activeFilters: {}
	}

	render() {
		const {users, emptyMessage, handleOpenView, getUsers} = this.props

		return (
			<div className='admin-table admin-table--users wrap--scrollx'>
				<table className="admin-table__table">
					<thead>
						<tr>
							<th>Registration <TableSort fieldName="created_at" refreshData={ getUsers } /></th>
							<th>Name <TableSort fieldName="first_name" refreshData={ getUsers } /></th>
							<th>User Type</th>
							<th>Email <TableSort fieldName="email" refreshData={ getUsers } /></th>
							<th>Employer <TableSort fieldName="organisation_name" refreshData={ getUsers } /></th>
							<th>Entries</th>
							<th>Successful</th>
							<th className="actions"><span className="sr-only">Actions</span></th>
						</tr>
					</thead>
					<tbody>
					{
						users.length
							? users.map((user) => <AdminUsersTableRow key={user.id} user={user} handleOpenView={handleOpenView} />)
							: <tr><td colSpan={100} align="center">{emptyMessage}</td></tr>
					}
					</tbody>
				</table>
			</div>
		)
	}
}

export default AdminUsersTable
