
// Libraries
import * as React from 'react'
import _ from 'lodash'
import qs from 'qs'

// Services
import ApiService from '../../../services/api'
import UserService from '../../../services/user'

// Context
import NotificationContext from '../../../contexts/notification'

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminUsersTableHeader from '../../../components/admin/users/adminUsersTableHeader'
import Pagination from '../../../components/pagination'
import AdminUsersTable from '../../../components/admin/users/adminUsersTable'
import AdminUserView from '../../../components/admin/users/adminUserView'
import AdminUserEdit from '../../../components/admin/users/adminUserEdit'
import AdminUserConfirmBan from '../../../components/admin/users/adminUserConfirmBan'
import AdminUserConfirmUnban from '../../../components/admin/users/adminUserConfirmUnban'
import AdminUserConfirmDelete from '../../../components/admin/users/adminUserConfirmDelete'
import AdminUserConfirmSendPasswordReset from '../../../components/admin/users/adminUserConfirmSendPasswordReset'
import AdminUserConfirmSendVerifyEmail from '../../../components/admin/users/adminUserConfirmSendVerifyEmail'
import {faUsers} from '@fortawesome/pro-solid-svg-icons'

class AdminUsersIndexPage extends React.Component {
	state = {
		activeFilters: {
			'filter': {},
			'include': null
		},
		users: [],
		pagination: [],
		emptyMessage: 'Retrieving user data ...',
		selectedUser: {},
		modalViewShow: false,
		modalEditShow: false,
		modalConfirmBanShow: false,
		modalConfirmUnbanShow: false,
		modalConfirmDeleteShow: false,
		modalConfirmSendPasswordResetShow: false,
		modalConfirmSendVerifyEmailShow: false,
	}

	static contextType = NotificationContext

	render() {
		const {activeFilters, users, pagination, emptyMessage, selectedUser,
			modalViewShow, modalEditShow, modalConfirmBanShow, modalConfirmUnbanShow, modalConfirmDeleteShow,
			modalConfirmSendPasswordResetShow, modalConfirmSendVerifyEmailShow} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table page--admin-table-users nav-blue-half">
				<Seo title="Users Management" />
				<div className="admin-table__wrap">
					<AdminUsersTableHeader
						titleText="Users Management" titleIcon={faUsers}
						searchValue={activeFilters.search} searchHandler={_.debounce(this.handleSearchChange, 500)} />

					<p className='filters-status'>
						Filter by:
						<a href="/admin/users/" className={activeFilters?.filter?.role_id === '1' && 'active'}>Carers</a>
						<a href="/admin/users/?filter[role_id]=2" className={activeFilters?.filter?.role_id === '2' && 'active'}>Admins</a>
						<a href="/admin/users/?filter[registration_complete]=0" className={activeFilters?.filter?.registration_complete === '0' && 'active'}>Abandoned</a>
					</p>

					<Pagination pagination={pagination} handlePaginationClick={this.handlePaginationClick} />

					<AdminUsersTable
						users={users}
						getUsers={this.getUsers}
						emptyMessage={emptyMessage}
						handleOpenView={this.handleOpenView} />

					{modalViewShow && <AdminUserView
						user={selectedUser}
						handleClose={() => this.setState({modalViewShow: false})}
						handleOpenEdit={this.handleOpenEdit}
						handleConfirmBan={this.handleConfirmBan}
						handleConfirmUnban={this.handleConfirmUnban}
						handleConfirmDelete={this.handleConfirmDelete}
						handleConfirmSendPasswordReset={() => this.setState({modalConfirmSendPasswordResetShow: true})}
						handleConfirmSendVerifyEmail={() => this.setState({modalConfirmSendVerifyEmailShow: true})} />}

					{modalEditShow && <AdminUserEdit
						user={selectedUser}
						handleClose={() => this.setState({
							modalEditShow: false,
							modalViewShow: true
						})}
						handleEditComplete={this.handleEditComplete} />}

					{modalConfirmBanShow && <AdminUserConfirmBan
						user={selectedUser}
						handleClose={() => this.setState({modalConfirmBanShow: false})}
						handleConfirm={this.handleBan} />}

					{modalConfirmUnbanShow && <AdminUserConfirmUnban
						user={selectedUser}
						handleClose={() => this.setState({modalConfirmUnbanShow: false})}
						handleConfirm={this.handleUnban} />}

					{modalConfirmDeleteShow && <AdminUserConfirmDelete
						user={selectedUser}
						handleClose={() => this.setState({modalConfirmDeleteShow: false})}
						handleConfirm={this.handleDelete} />}

					{modalConfirmSendPasswordResetShow && <AdminUserConfirmSendPasswordReset
						user={selectedUser}
						handleClose={() => this.setState({modalConfirmSendPasswordResetShow: false})}
						handleConfirm={this.handleSendPasswordReset} />}

					{modalConfirmSendVerifyEmailShow && <AdminUserConfirmSendVerifyEmail
						user={selectedUser}
						handleClose={() => this.setState({modalConfirmSendVerifyEmailShow: false})}
						handleConfirm={this.handleSendVerifyEmail} />}
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		// todo: add isAdmin middleware check when available
		this.setState({
			authCheck: true
		})

		const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

		Promise.all([
			this.getUsers({...activeFilters, ...searchParams}, false)
				.catch(() => addNotification('There was an error fetching the users.', 'error'))
		]).then(() => {
			this.setState({
				activeFilters:{...activeFilters, ...searchParams},
				emptyMessage: 'No users found, try adjusting your filters'
			})
		})
	}

	getUsers = (queryParams) => {
		const apiService = new ApiService()
		queryParams = {...this.state.activeFilters, ...queryParams}

		if (!queryParams['filter']['role_id'] && !queryParams['filter']['registration_complete']) {
			queryParams['filter']['role_id'] = "1"
		}
		
		return apiService.get('users', {}, queryParams).then( (response) => {
			this.setState({
				users: response.data.data,
				pagination: response.data,
				activeFilters: queryParams
			})
		}).catch(err => console.error(err))
	}

	handleSearchChange = (searchValue) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		if (searchValue) {
			activeFilters['search'] = searchValue
		} else if (activeFilters['search']) {
			delete activeFilters['search']
		}

		if (activeFilters['page']) {
			delete activeFilters['page']
		}

		this.setState({activeFilters}, () => {
			this.getUsers(activeFilters)
				.catch(() => addNotification('There was an error filtering the users.', 'error'))
		})
	}

	handlePaginationClick = (page) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		activeFilters['page'] = page

		this.setState({activeFilters}, () => {
			this.getUsers(activeFilters)
				.catch(() => addNotification('There was an error filtering the users.', 'error'))
		})
	}

	handleOpenView = (user_id) => {
		const apiService = new ApiService()
		return apiService.get(`users/${user_id}`)
			.then( (response) => {
				this.setState({
					selectedUser: response.data,
					modalViewShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleOpenEdit = (user_id) => {
		const apiService = new ApiService()
		return apiService.get(`users/${user_id}`)
			.then( (response) => {
				this.setState({
					selectedUser: response.data,
					modalEditShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleConfirmBan = (user_id) => {
		const apiService = new ApiService()
		return apiService.get(`users/${user_id}`)
			.then( (response) => {
				this.setState({
					selectedUser: response.data,
					modalConfirmBanShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleConfirmUnban = (user_id) => {
		const apiService = new ApiService()
		return apiService.get(`users/${user_id}`)
			.then( (response) => {
				this.setState({
					selectedUser: response.data,
					modalConfirmUnbanShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleConfirmDelete = (user_id) => {
		const apiService = new ApiService()
		return apiService.get(`users/${user_id}`)
			.then( (response) => {
				this.setState({
					selectedUser: response.data,
					modalConfirmDeleteShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleSendPasswordReset = () => {
		const {addNotification} = this.context
		const {selectedUser} = this.state
		const userService = new UserService()

		return userService.resendResetPasswordLink(selectedUser.email)
			.then( (response) => {
				if (response.success) {
					addNotification('Email sent', 'success')
					this.setState({
						modalConfirmSendPasswordResetShow: false
					})
				}
				else {
					addNotification('Unable to send email', 'error')
				}
			})
			.catch(err => {
				console.error(err)
				addNotification('Unable to send email', 'error')
			})
	}

	handleSendVerifyEmail = () => {
		const {addNotification} = this.context
		const {selectedUser} = this.state
		const userService = new UserService()

		return userService.resendVerificationLink(selectedUser.registration_token)
			.then( (response) => {
				if (response.success) {
					addNotification('Email sent', 'success')
					this.setState({
						modalConfirmSendVerifyEmailShow: false
					})
				}
				else {
					addNotification('Unable to send email', 'error')
				}
			})
			.catch(err => {
				console.error(err)
				addNotification('Unable to send email', 'error')
			})
	}

	handleBan = async (user_id, reason) => {
		const {addNotification} = this.context

		const apiService = new ApiService()
		const responseOptions = {
			body: JSON.stringify({
				reason: reason,
			}),
		}

		return apiService.post(`users/${user_id}/ban`, responseOptions)
			.then( (response) => {
				if (response.success) {
					addNotification('User banned', 'success')

					this.updateStateSelectedUser(response.data)
					this.updateStateUsers(response.data)
					this.setState({
						modalConfirmBanShow: false
					})
				}
				else {
					addNotification('Unable to ban user', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	handleUnban = (user_id) => {
		const apiService = new ApiService()
		const {addNotification} = this.context

		return apiService.post(`users/${user_id}/unban`)
			.then( (response) => {
				if (response.success) {
					addNotification('User reactivated', 'success')
					this.updateStateSelectedUser(response.data)
					this.updateStateUsers(response.data)
					this.setState({
						modalConfirmUnbanShow: false
					})
				}
				else {
					addNotification('Unable to reactivate user', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	handleDelete = (user_id) => {
		const apiService = new ApiService()
		const {addNotification} = this.context

		return apiService.delete(`users/${user_id}`)
			.then( (response) => {
				if (response.data?.deleted) {
					addNotification('User deleted', 'success')
					this.updateStateUsers({id: user_id}, true)
					this.setState({
						modalViewShow: false,
						modalConfirmDeleteShow: false
					})
				} else {
					addNotification('Unable to delete user', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	handleEditComplete = (user) => {
		const {selectedUser} = this.state

		// did postal code change?
		if (user.registration_complete === 1 && selectedUser.addresses[0]?.postal_code !== user.addresses[0].postal_code) {
			user.postal_code_change_count = parseInt(user.postal_code_change_count) + 1
		}

		this.updateStateSelectedUser(user)
		this.updateStateUsers(user)
		this.setState({
			modalEditShow: false,
			modalViewShow: true
		})
	}

	updateStateSelectedUser = (user) => {
		this.setState(prevState => ({
			selectedUser: {...prevState.selectedUser, ...user}
		}))
	}

	updateStateUsers = (user, removeUser = false) => {
		const {users} = this.state

		// update users state
		users.forEach((existingUser, key) => {
			if (existingUser.id === user.id) {
				if (removeUser) {
					users.splice(key, 1)
				}
				else {
					users[key] = {...existingUser, ...user}
				}
			}
		})

		this.setState({users})
	}
}

export default AdminUsersIndexPage
