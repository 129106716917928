
// Libraries
import * as React from 'react'
import moment from "moment"
import Classnames from 'classnames'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons'

class AdminUsersTableRow extends React.Component {
	render() {
		const {user, handleOpenView} = this.props
		let classNames = Classnames([
			'admin-table__row',
			{
				'admin-table__row--banned': user.banned_at,
			},
		])

		return <tr className={classNames}>
			<td className="admin-table__cell--registered">{moment(user.created_at).format('DD MMM YYYY')}</td>
			<td className="admin-table__cell--name">{user.first_name} {user.last_name}</td>
			<td className="admin-table__cell--role">{user.roles?.length ? user.roles[0].name : '-'}</td>
			<td className="admin-table__cell--email">{user.email}</td>
			<td className="admin-table__cell--employer">{user.organisation ? user.organisation.name : '-'}</td>
			<td className="admin-table__cell--ballot-count">{user.ballots_count}</td>
			<td className="admin-table__cell--success-count">{user.ballots_won_count}</td>
			<td className="admin-table__cell--actions">
				<button onClick={() => handleOpenView(user.id)}>
					<FontAwesomeIcon icon={faArrowRight} title="Edit icon" /><span className="sr-only"> View/Edit</span>
				</button>
			</td>
		</tr>
	}
}

export default AdminUsersTableRow
