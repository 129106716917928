
// Libraries
import * as React from 'react'

// Components
import Modal from "../../modal"
import InputFactory from "../../inputFactory"
import Button from "../../button"

class AdminUserConfirmBan extends React.Component {
	state = {
		value: '',
		error: '',
		isConfirming: false
	}

	render() {
		const {user, handleClose} = this.props
		const {isConfirming} = this.state

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-confirm">
				<h2>Ban {user.first_name} {user.last_name}?</h2>
				<InputFactory
					type="textarea"
					name="reason"
					label="Please specify the reason for banning"
					placeholder="Reason(s) for ban"
					onChange={this.handleInputChange}
					rows="5"
					value={this.state.value}
					error={this.state.error}
				/>
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>Back</Button></li>
					<li><Button onClick={this.handleConfirm} isLoading={isConfirming} colorPomegranate>Ban User</Button></li>
				</ul>
			</div>
		</Modal>
	}

	handleInputChange = value => {
		this.setState({value: value || ''})
	}

	handleConfirm = () => {
		const {value} = this.state
		const {user, handleConfirm} = this.props

		this.setState({
			isConfirming: true
		})

		if (!value) {
			this.setState({
				error: 'Reason for banning the user is required',
				isConfirming: false
			})
		}
		else {
			handleConfirm(user.id, this.state.value).then(() => {
				this.setState({
					isConfirming: false
				})
			})
		}
	}
}

export default AdminUserConfirmBan
